<template>
  <div class="w-full">
    <div class="flex-shrink-0 p-4 border-b border-gray-100 flex flex-col gap-2">
      <fw-heading>{{ $t('settings') }}</fw-heading>
      <fw-label v-if="translator">{{ $t('translation_state') }}</fw-label>
      <fw-label v-else>{{ $t('version_state') }}</fw-label>
      <b-dropdown v-if="canChangeState && version" aria-role="list" position="is-bottom-right">
        <template #trigger="{ active }">
          <fw-button expanded type="xlight" icon-right="chevron-down">
            {{ statesLabes[language][version.state] }}
          </fw-button>
        </template>
        <b-dropdown-item v-for="(state, s) in states" :key="s" paddingless aria-role="listitem">
          <fw-button
            type="simple"
            size="sm"
            class="w-full flex items-center gap-2"
            :label="statesLabes[language][state]"
            @click.native="changeState(state)"
          >
            {{ statesLabes[language][state] }}
          </fw-button>
        </b-dropdown-item>
      </b-dropdown>
      <div v-else class="text-sm">{{ version.state }}</div>
      <fw-label>{{ $t('share_and_permissions') }}</fw-label>
      <fw-button
        class="flex-1 relative overflow-hidden cursor-pointer w-full"
        type="primary"
        @click.native="$emit('share-document')"
      >
        {{ $t('share_document') }}
      </fw-button>
      <fw-label>{{ $t('import_and_export') }}</fw-label>
      <div class="flex gap-3">
        <div v-if="debugMode" class="flex-1 flex-shrink-0">
          <fw-button class="flex-1 relative overflow-hidden cursor-pointer w-full" type="xlight">
            <label for="fileupload">{{ $t('import') }}</label>
            <input
              id="fileupload"
              class="opacity-0 absolute top-0 left-0 right-0 bottom-0 cursor-pointer"
              type="file"
              name="fileupload"
              accept="application/xliff+xml"
              @change="importFile"
            />
          </fw-button>
        </div>
        <b-dropdown
          class="flex-1 flex-shrink-0 export-button"
          :scrollable="true"
          :max-height="200"
          aria-role="list"
          position="is-bottom-left"
        >
          <template #trigger="{}">
            <fw-button type="xlight" class="w-full">
              {{ $t('export') }}
            </fw-button>
          </template>
          <b-dropdown-item v-if="translator && debugMode" aria-role="listitem" @click="$emit('export-file', 'xliff')">
            XLIFF
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('export-file', 'text')">
            {{ $t('text_file') }}
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="$emit('export-file', 'html')">
            {{ $t('html_file') }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div v-if="myrole == 'owner'" class="flex flex-col gap-2">
        <fw-label>{{ $t('danger_zone') }}</fw-label>
        <fw-button v-if="translator" type="danger" class="w-full" @click.native="$emit('delete-version')">
          {{ $t('delete_translation') }}
        </fw-button>
        <fw-button type="danger" class="w-full" @click.native="$emit('delete-document')">
          {{ $t('delete_document') }}
        </fw-button>
      </div>
    </div>
    <div class="p-4 flex flex-col gap-2"></div>
  </div>
</template>

<script>
export default {
  props: {
    translator: {
      type: Boolean,
      default: false
    },
    page: {
      type: Object,
      default: () => ({})
    },
    version: {
      type: Object,
      default: () => ({})
    },
    myrole: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      states: ['draft', 'review', 'published', 'closed'],
      statesLabes: {
        pt: {
          draft: 'Rascunho',
          review: 'Em revisão',
          published: 'Publicado',
          closed: 'Bloqueado'
        },
        en: {
          draft: 'Draft',
          review: 'Under review',
          published: 'Published',
          closed: 'Locked'
        }
      }
    }
  },
  computed: {
    canChangeState() {
      return this.myrole === 'owner' || this.myrole === 'manager'
    },
    language() {
      return this.$i18n.locale
    },
    debugMode() {
      return localStorage.getItem('fw-debug') === 'true'
    }
  },
  methods: {
    importFile(event) {
      const file = event.target.files[0]
      this.$emit('import-file', file)
    },
    changeState(state) {
      this.$emit('change-state', state)
    }
  }
}
</script>

<style>
.export-button .dropdown-trigger {
  width: 100%;
}
</style>

<i18n>
{
  "en": {
    "settings": "Settings",
    "translation_state": "Translation state",
    "version_state": "Document version state",
    "share_and_permissions": "Share and permissions",
    "share_document": "Share document",
    "import_and_export": "Export document",
    "import": "Import",
    "export": "Export",
    "text_file": "Text file",
    "html_file": "HTML file",
    "delete_document": "Delete document",
    "delete_translation": "Delete translation",
    "danger_zone": "Danger zone"
  },
  "pt": {
    "settings": "Definições",
    "translation_state": "Estado da tradução",
    "version_state": "Estado da versão do documento",
    "share_and_permissions": "Partilha e permissões",
    "share_document": "Partilhar documento",
    "import_and_export": "Exportar documento",
    "import": "Importar",
    "export": "Exportar",
    "text_file": "Ficheiro de texto",
    "html_file": "Ficheiro HTML",
    "delete_document": "Eliminar documento",
    "delete_translation": "Eliminar tradução",
    "danger_zone": "Zona de perigo"
  }
}
</i18n>
